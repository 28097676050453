import React from "react";
import Layout from "../components/Layout";
import phoneIcon from "../images/phone.svg";
import emailIcon from "../images/email.svg";
import WhatsAppButton from "../components/WhatsAppButton";
import ContatoItem from "../components/ContatoItem";
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import InsertMetaTags from "../components/InsertMetaTags";
import { localiseText } from "../translations";

const ContatoPage = ({ data }) => {
  const { contentfulContato, site: { siteMetadata: { locale } } } = data;

  return (
    <Layout pageTitle="Contato" locale={locale}>
      <InsertMetaTags title="Priscila Tambalo Design - Contact" />
      {renderRichText(contentfulContato.fraseInicial)}
      <ContatoItem
        label={
          <a href={`tel:${contentfulContato.telefoneCelular}`}>
            {contentfulContato.telefoneCelular}
          </a>
        }
        icon={phoneIcon}
      />

      <ContatoItem
        label={
          <a href={`mailto:${contentfulContato.email}`}>
            {contentfulContato.email}
          </a>
        }
        icon={emailIcon}
      />
      <WhatsAppButton numeroWhatsapp={contentfulContato.celularWhatsApp}>
        {localiseText(locale, 'lets_chat_btn')}
      </WhatsAppButton>
    </Layout>
  );
};

export const query = graphql`
  query ($locale: String!) {
    contentfulContato(node_locale: { eq: $locale } ) {
      fraseInicial {
        raw
      }
      telefoneCelular
      email
      celularWhatsApp
    }
    site { siteMetadata { locale } }
  }
`;

export default ContatoPage;
